@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?95781692');
  src: url('../font/fontello.eot?95781692#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?95781692') format('woff2'),
       url('../font/fontello.woff?95781692') format('woff'),
       url('../font/fontello.ttf?95781692') format('truetype'),
       url('../font/fontello.svg?95781692#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?95781692#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-down-dir:before { content: '\e800'; } /* '' */
.icon-cancel-circled:before { content: '\e801'; } /* '' */
.icon-mail:before { content: '\e801'; } /* '' */
.icon-help-circled:before { content: '\e802'; } /* '' */
.icon-mouse:before { content: '\e802'; } /* '' */
.icon-left-open:before { content: '\e803'; } /* '' */
.icon-group:before { content: '\e804'; } /* '' */
.icon-megaphone:before { content: '\e805'; } /* '' */
.icon-chart-line:before { content: '\e806'; } /* '' */
.icon-article:before { content: '\e807'; } /* '' */
.icon-chat:before { content: '\e808'; } /* '' */
.icon-th:before { content: '\e809'; } /* '' */
.icon-vcard:before { content: '\e80a'; } /* '' */
.icon-left-circle:before { content: '\e80b'; } /* '' */
.icon-pencil:before { content: '\e80c'; } /* '' */
.icon-doc-text-inv-1:before { content: '\e80d'; } /* '' */
.icon-calendar:before { content: '\e80e'; } /* '' */
.icon-search:before { content: '\e80f'; } /* '' */
.icon-trash-empty:before { content: '\e810'; } /* '' */
.icon-plus-circle:before { content: '\e811'; } /* '' */
.icon-eye:before { content: '\e812'; } /* '' */
.icon-list-add:before { content: '\e813'; } /* '' */
.icon-down-circle:before { content: '\e814'; } /* '' */
.icon-up-circle:before { content: '\e815'; } /* '' */
.icon-list:before { content: '\e816'; } /* '' */
.icon-briefcase:before { content: '\e817'; } /* '' */
.icon-key:before { content: '\e818'; } /* '' */
.icon-megaphone-1:before { content: '\e819'; } /* '' */
.icon-export:before { content: '\e81a'; } /* '' */
.icon-phone:before { content: '\e81b'; } /* '' */
.icon-comment-alt:before { content: '\e81c'; } /* '' */
.icon-cancel:before { content: '\e81d'; } /* '' */
.icon-attention:before { content: '\e81e'; } /* '' */
.icon-user:before { content: '\e81f'; } /* '' */
.icon-attach:before { content: '\e820'; } /* '' */
.icon-picture:before { content: '\e821'; } /* '' */
.icon-headphones:before { content: '\e822'; } /* '' */
.icon-doc:before { content: '\e823'; } /* '' */
.icon-down-open:before { content: '\e824'; } /* '' */
.icon-up-open:before { content: '\e825'; } /* '' */
.icon-right-open:before { content: '\e826'; } /* '' */
.icon-up-dir:before { content: '\e827'; } /* '' */
.icon-video:before { content: '\e828'; } /* '' */
.icon-comment-1:before { content: '\e829'; } /* '' */
.icon-arrow-curved:before { content: '\e82a'; } /* '' */
.icon-users:before { content: '\e82b'; } /* '' */
.icon-users-1:before { content: '\e82c'; } /* '' */
.icon-comment-2:before { content: '\e82d'; } /* '' */
.icon-comment-3:before { content: '\e82e'; } /* '' */
.icon-clock:before { content: '\e82f'; } /* '' */
.icon-lock-open:before { content: '\e830'; } /* '' */
.icon-minus-circled:before { content: '\e831'; } /* '' */
.icon-commerical-building:before { content: '\e832'; } /* '' */
.icon-reddit:before { content: '\e833'; } /* '' */
.icon-minus-circle:before { content: '\e834'; } /* '' */
.icon-info-circled:before { content: '\e835'; } /* '' */
.icon-cog:before { content: '\e836'; } /* '' */
.icon-down-circled:before { content: '\e837'; } /* '' */
.icon-spin5:before { content: '\e838'; } /* '' */
.icon-upload:before { content: '\f02f'; } /* '' */
.icon-chat-1:before { content: '\f03d'; } /* '' */
.icon-tasks:before { content: '\f0ae'; } /* '' */
.icon-filter:before { content: '\f0b0'; } /* '' */
.icon-docs:before { content: '\f0c5'; } /* '' */
.icon-money:before { content: '\f0d6'; } /* '' */
.icon-comment-empty:before { content: '\f0e5'; } /* '' */
.icon-bell-alt:before { content: '\f0f3'; } /* '' */
.icon-doc-text:before { content: '\f0f6'; } /* '' */
.icon-smile:before { content: '\f118'; } /* '' */
.icon-minus-squared-alt:before { content: '\f147'; } /* '' */
.icon-doc-text-inv:before { content: '\f15c'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
.icon-plus-squared-alt:before { content: '\f196'; } /* '' */
.icon-file-excel:before { content: '\f1c3'; } /* '' */
.icon-file-video:before { content: '\f1c8'; } /* '' */
.icon-paper-plane:before { content: '\f1d8'; } /* '' */
.icon-history:before { content: '\f1da'; } /* '' */
.icon-bell-off:before { content: '\f1f6'; } /* '' */
.icon-whatsapp:before { content: '\f232'; } /* '' */
.icon-user-plus:before { content: '\f234'; } /* '' */
.icon-hourglass-2:before { content: '\f252'; } /* '' */
.icon-calendar-plus-o:before { content: '\f271'; } /* '' */
.icon-calendar-check-o:before { content: '\f274'; } /* '' */
.icon-user-o:before { content: '\f2c0'; } /* '' */
.icon-facebook-squared:before { content: '\f308'; } /* '' */
.icon-comment:before { content: '\f4ac'; } /* '' */
