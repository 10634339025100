.custom-button {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    color: white; 
    text-decoration: underline;
    cursor: pointer;
  }
  
  .custom-button:hover {
    text-decoration: none;
    color: blue; 
  }