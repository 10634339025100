.install-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

  /* Mostrar el botón solo en dispositivos móviles */
.install-button-container {
    display: none;
  }
  
 @media (max-width: 768px) {
    /* Mostrar el botón en dispositivos con un ancho máximo de 768px */
    .install-button-container {
      display: flex;
    }
}

#setup_button{
  margin-top: 3rem;
}